import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'
import Skus from '../components/skus'

const post = {
  title: "Nos bombes de bains moussantes aux huiles essentielles bio",
  description: 'Nos produits sont artisanaux, fait main et respectueux pour la peau pour un moment de douceur',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}

const BombeDeBain = () => {
  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image}/>
      <Layout displayIcons={true} displayBasket={true}>
      <div className='main'>
        <div className='main-content'>
          <h2>Nos bombes de bains</h2>
          <p>Tout nos produits son fait main avec des huiles essentielles bio <br/><br/>Profiter d'un moment relaxant, moussant et odorant</p>
          <p></p>
          <Skus></Skus>
        </div>
      </div>
    </Layout>
    </>
   
  )
}

export default BombeDeBain
